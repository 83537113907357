import React, { useMemo } from 'react';
import { ExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import {
	ContextualAnalyticsData,
	SCREEN,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import ProjectsDirectoryV3 from '@atlassian/jira-projects-directory-v3/src/ui/index.tsx';
import SpaErrorBoundary from '@atlassian/jira-spa-apps-common/src/error-boundary/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { METRICS_CONFIG } from './constants.tsx';

type Props = {
	isAdminProjectDirectoryRoute?: boolean;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ isAdminProjectDirectoryRoute = false }: Props) => {
	const metricsConfig = useMemo(
		() => (isAdminProjectDirectoryRoute ? METRICS_CONFIG.admin : METRICS_CONFIG.default),
		[isAdminProjectDirectoryRoute],
	);

	return (
		<ContextualAnalyticsData sourceName={metricsConfig.sourceName} sourceType={SCREEN}>
			<MarkProductStart />
			<ExperienceStart
				experience={metricsConfig.experienceKey}
				analyticsSource={metricsConfig.experienceSource}
			/>
			<SpaErrorBoundary id={metricsConfig.errorBoundary}>
				<UFOSegment name="projects-directory-v3">
					<ProjectsDirectoryV3 isAdminSettingsContext={isAdminProjectDirectoryRoute} />
					<FireScreenAnalytics />
				</UFOSegment>
			</SpaErrorBoundary>
		</ContextualAnalyticsData>
	);
};
