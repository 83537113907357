import React, { useCallback } from 'react';
import { SearchControl as BaseSearchControl } from '@atlassian/jira-directory-base-v3/src/ui/refinement-bar/search-control/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FilterFields } from '@atlassian/jira-projects-directory-v3-utils/src/constants.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useScopedFilter } from '../../../../controllers/filter-state/index.tsx';
import messages from './messages.tsx';

export const SearchControl = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'refinementBar',
	});

	const [value, { updateFilter }] = useScopedFilter(FilterFields.Keyword);

	const onChange = useCallback(
		(newValue: string) => {
			updateFilter(FilterFields.Keyword, newValue);
			fireUIAnalytics(analyticsEvent, 'FilterSearch');
		},
		[analyticsEvent, updateFilter],
	);

	return (
		<BaseSearchControl
			name={FilterFields.Keyword}
			value={value}
			onChange={onChange}
			placeholderAlwaysVisible
			placeholder={formatMessage(
				isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_1')
					? messages.placeholder
					: messages.placeholderOld,
			)}
		/>
	);
};
